.image-container {
  display: flex;
  height: 100vh;
}

.desktop-image {
  display: block;
  width: 100%;
  min-width: 1024px;
  height: auto;
}

.mobile-image {
  display: none;
}

img {
  background-size: cover !important;
  height: 100%;
  width: 100%;
  object-fit: scale-down;
}

.imagesliderwrapper {
  height: 721px;
}

@media only screen and (max-width: 1024px) {
  .desktop-image {
    display: none;
  }

  .mobile-image {
    display: block;
    width: 100%;
    min-width: 320px;
    height: auto;
  }
}

html {
  background-color: #3cb4e5;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.video {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
}

.video-wrapper {
  position: relative;
  width: 35%; /* Örneğin video genişliği %50 olsun */
  height: auto;
  margin: 0 auto; /* Horizontally center the video */
}

@media only screen and (max-width: 1024px) {
  .video-wrapper {
    width: 100%;
  }
}

.video {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%; /* 16:9 aspect ratio */
  background-color: #000;
}

.video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
